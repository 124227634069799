import React from "react";
import Slider from "react-slick";

import tw from "tailwind.macro";
import { css } from "@emotion/core";

import { sm, md, lg, xl } from "../styles/breakpoints";
import { blogMd, blogLg } from "../styles/breakpoints";
import { rem } from "../styles/utilities.styles.js";
import { Chevron } from "./icons";
import Typography from "../components/Typography";

const styles = {
  wrap: tw`max-w-lg mx-auto lg:max-w-4xl lg:px-24`,
  title: css`
    padding: 0 ${rem(20)};
    margin-bottom: ${rem(40)};

    @media (${sm}) {
      margin-bottom: ${rem(48)};
    }

    @media (${md}) {
      margin-bottom: ${rem(56)};
    }
  `,
  slick: css`
    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: ${rem(
        106 - 20
      )}; /* height of dots and margin; minus track padding */

      @media (${lg}) {
        margin-bottom: ${rem(122 - 20)};
      }

      @media (${xl}) {
        margin-bottom: ${rem(138 - 20)};
      }

      @media (min-width: 860px) {
        margin-left: ${rem(-20)};
        margin-right: ${rem(-20)};
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      transform: translate3d(0, 0, 0);

      &:focus {
        outline: none;
      }

      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
    }
    .slick-track {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      transform: translate3d(0, 0, 0);
      padding-bottom: ${rem(20)};
    }
    .slick-slide {
      display: flex;
      justify-content: center;
      padding: 0 ${rem(16)};

      @media (${md}) {
        padding: 0 ${rem(20)};
      }
    }

    /* IE11 flex box bug fix */
    .slick-slide > div {
      width: 100%;
    }

    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-arrow {
      position: absolute;
      top: calc(50% - ${rem(20)}); /* offset for shadow padding */
      color: #e1e1e1;
      z-index: 20;
      visibility: hidden;

      @media (${md}) {
        visibility: visible;
      }
    }
    .slick-arrow.slick-hidden {
      display: none;
    }
  `,
  dots: css`
    position: absolute;
    list-style: none;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: ${rem(40 - 20)} auto ${rem(56)}; /* minus track padding */

    @media (${sm}) {
      margin-bottom: ${rem(56)};
    }

    @media (${lg}) {
      margin-bottom: ${rem(56)};
    }

    @media (${xl}) {
      margin: ${rem(56 - 20)} auto ${rem(72)};
    }
  `,
  pager: css`
    padding: ${rem(4)};
  `,
  dot: css`
    display: block;
    width: ${rem(10)};
    height: ${rem(10)};
    background-color: #d8dadd;
    border-radius: 100%;

    .slick-active & {
      background-color: #636464;
    }
  `,
  next: css`
    transform: translateY(-50%);
    right: ${rem(16)};

    @media (${lg}) {
      right: ${rem(-40)};
    }
  `,
  prev: css`
    left: ${rem(16)};
    transform: translateY(-50%) scale(-1);

    @media (${lg}) {
      left: ${rem(-40)};
    }
  `,
  card: css`
    ${tw`flex flex-col bg-white`};
    padding: ${rem(24)} ${rem(32)};
    margin: 0 auto;
    max-width: ${rem(656)};
    height: 100%;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.04);

    @media (${sm}) {
      padding: ${rem(48)} ${rem(40)};
    }

    @media (${xl}) {
      padding: ${rem(56)} ${rem(48)};
    }
  `,
  quote: css`
    ${tw`text-lg xl:text-2xl font-light leading-normal`};
    margin-bottom: ${rem(24)};

    @media (${md}) {
      margin-bottom: ${rem(32)};
    }

    &::before {
      content: "“";
    }

    &::after {
      content: "”";
    }
  `,
  cite: tw`mt-auto text-base xl:text-xl leading-normal roman`,
  name: tw`block font-bold`,
  loc: tw`block font-light text-grey-darker text-sm xl:text-lg`,
};

const QuoteCard = ({ quote, name, location }) => (
  <blockquote css={styles.card}>
    <q css={styles.quote}>{quote}</q>
    <cite css={styles.cite}>
      <span css={styles.name}>{name}</span>
      <span css={styles.loc}>{location}</span>
    </cite>
  </blockquote>
);

const Dots = (dots) => <ul css={styles.dots}>{dots}</ul>;

const Paging = (i) => (
  <button css={styles.pager} aria-label={`Go to slide ${i}`}>
    <span css={styles.dot} />
  </button>
);

const Arrow = ({ currentSlide, slideCount, ...props }) => (
  <button {...props}>
    <Chevron />
  </button>
);

const Testimonials = ({ title, quotes }) => {
  return (
    <section css={[styles.wrap, styles.slick]}>
      <Typography
        weight="bold"
        align="center"
        variant="Inter-1"
        mb={4}
        as="h2"
        responsive={[
          { size: blogMd, variant: "Inter2" },
          { size: blogLg, variant: "Inter4" },
        ]}
        css={styles.title}
      >
        {title}
      </Typography>
      <Slider
        dots
        infinite
        appendDots={Dots}
        customPaging={Paging}
        slidesToShow={2}
        slidesToScroll={2}
        nextArrow={<Arrow css={styles.next} aria-label="next" />}
        prevArrow={<Arrow css={styles.prev} aria-label="previous" />}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {quotes.map(({ id, ...quote }) => (
          <QuoteCard key={id} {...quote} />
        ))}
      </Slider>
    </section>
  );
};

Testimonials.defaultProps = {
  title: "What TED Masterclass participants are saying:",
  quotes: [
    {
      id: 1,
      quote:
        "I just finished this course and wow!!! This is an amazing app to guide you to becoming a better speaker. The videos are very well done and full of information. This app not only helped me become a better speaker, it helped me to become a better person.",
      name: "Traci A.",
      location: "United States",
    },
    {
      id: 4,
      quote:
        "I liked the structured approach. I am used to developing ‘story boards’ and fleshing out a flow, but this way of doing it was fresh and helpful.",
      name: "Terry C.",
      location: "United States",
    },
    {
      id: 2,
      quote:
        "It is just so to the point! Impressive how this condensed best-practice guide enables effective progress. This seems to be the best web-based training ever!",
      name: "Helmut P.",
      location: "Austria",
    },
    {
      id: 3,
      quote:
        "I had purchased the TED Talk book earlier. The course provided now is a bit similar however it is animated and designed in such a creative way which makes it easy to understand everything so well.",
      name: "Ali A.",
      location: "United Arab Emirates",
    },
  ],
};

export default Testimonials;
